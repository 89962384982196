<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">刷新</el-button>
          <el-button type="primary" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        show-summary
        :header-cell-style="{ fontSize: '18px', color: '#000', 'font-family': 'MicrosoftyaHei' }">
        <el-table-column label="沿江街道“爱社区·共同富裕社”各社区推进情况"
              align="center" >
          <el-table-column
            prop="no"
            label="序号"
            width="50"
            align="center"
			sortable>
          </el-table-column>
          <el-table-column
            prop="communityName"
            label="社区"
            align="center"
			sortable>
          </el-table-column>
          <el-table-column
            prop="volunteerCount"
            label="社员人数"
            align="center"
			sortable>
          </el-table-column>
          <el-table-column
            prop="cjVolunteerCount"
            label="特殊困难群体人数"
            align="center"
			sortable>
          </el-table-column>
          <el-table-column
            prop="cpcVolunteerCount"
            label="党员人数"
            align="center"
			sortable>
          </el-table-column>
          <el-table-column
            prop="volActivityCount"
            label="发布任务数"
            align="center"
            width="95"
			sortable>
          </el-table-column>
          <el-table-column
            prop="actActivityCount"
            label="举办活动数"
            align="center"
            width="95"
			sortable>
          </el-table-column>
          <el-table-column
            prop="shopCount"
            label="推荐商户数"
            align="center"
            width="95"
			sortable>
          </el-table-column>
          <el-table-column label="发放积分"
            align="center">
            <el-table-column
              prop="generalPoint"
              align="center"
              label="消费积分"
			  sortable>
            </el-table-column>
            <el-table-column
              prop="honerPoint"
              align="center"
              label="荣誉积分"
			  sortable>
            </el-table-column>
            <el-table-column
              prop="totalPoint"
              align="center"
              label="总积分"
			  sortable>
            </el-table-column>
          </el-table-column>
          <el-table-column label="收回积分"
              align="center">
            <el-table-column
              prop="generalUsablePoint"
              align="center"
              label="已消费积分（商户收回） "
              width="140"
			  sortable>
            </el-table-column>
            <el-table-column
              prop="incomePoint"
              align="center"
              label="消费积分收入（社区收回）( 含前期清退积分 )"
              width="180"
			  sortable>
            </el-table-column>
            <el-table-column
              prop="generalUsablevolPoint"
              align="center"
              label="荣誉积分收入（社区收回）"
              width="140"
			  sortable>
            </el-table-column>
            <el-table-column
              prop="shopPoint"
              align="center"
              label="推荐商户消费积分 "
              width="140"
			  sortable>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { promotionSituationList, exportExcel } from '@/api/reportStatistics'
export default {
  data() {
    return {
      loading: false,
      formInline: {
        time: '',
      },
      tableData: [],
      headLabel: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    onSearch() {
      this.getList();
    },
    //获取商户近一个月每天获取积分
    getList() {
      this.loading = true;
      promotionSituationList().then( res => {
        if(res.result == 200) {
          this.tableData = res.detail;
        }
        this.loading = false;
      })
    },
    //导出表格
    exportXcle() {
      exportExcel({
        type: 7
      }).then(res => {
        const content = res;
        const blob = new Blob([content]) //构造一个blob对象来处理数据
        const fileName =  '沿江街道“爱社区·共同富裕社”各社区推进情况.xlsx';
        const link = document.createElement('a') //创建a标签
        link.download = fileName //a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click() //执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link) //释放标签
      })
    },
  },
};
</script>
<style lang="scss" >
.tablebox{
	.el-table__body-wrapper{
	  font-size: 16px;  
	} 
	.el-table__footer-wrapper{
	  font-size: 16px;  
	} 
}  
</style>
